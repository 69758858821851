<template>
	<div class="page_area">
		<div class="top_area" :style="{ 'background-image': `url(${require('@/assets/image/top_area_bg.png')})` }">
			<div class="top_center">
				<van-image round width="72px" height="72px" :src="userInfo.profile" />
				<div class="top_center_text fs16">
					<div>{{ userInfo.nick }}</div>
					<!-- <div>{{ userInfo.phone }}</div> -->
				</div>
				<!-- <div class="tip ">
					<div>信息修改</div>
					<div style="text-align: center;"><img style="width:19px;height:9px;margin:0 auto" :src="require('@/assets/image/home_tip.png')" /></div>
				</div> -->
			</div>
		</div>
		<!-- 金刚区 -->
		<van-grid :column-num="4" class="grid_area">
			<van-grid-item :icon="require('@/assets/image/grid01.png')" text="报修" @click="onRepairs" />
			<van-grid-item :icon="require('@/assets/image/grid02.png')" text="投诉" @click="onComplaint" />
			<van-grid-item :icon="require('@/assets/image/grid03.png')" text="资讯" @click="onInform" />
			<van-grid-item :icon="require('@/assets/image/grid04.png')" text="我的" @click="onMyServer" />
		</van-grid>

		<!-- 轮播图 -->
		<van-swipe :autoplay="3000" class="swipe_wrap">
			<van-swipe-item><img class="swipe_item" src="@/assets/image/banner1.png" /></van-swipe-item>
			<van-swipe-item><img class="swipe_item" src="@/assets/image/banner2.png" /></van-swipe-item>
		</van-swipe>

		<!-- 滚动栏 -->
		<!-- <van-notice-bar left-icon="volume-o"
                    :scrollable="false"
                    class="mt_8">
      <van-swipe vertical
                 class="notice-swipe"
                 :autoplay="3000"
                 :show-indicators="false">
        <van-swipe-item>内容 1</van-swipe-item>
        <van-swipe-item>内容 2</van-swipe-item>
        <van-swipe-item>内容 3</van-swipe-item>
      </van-swipe>
    </van-notice-bar> -->

		<!-- 分割线 -->
		<!-- <div class="split_8 mt_8"></div> -->
		<!-- 新闻标题 -->
		<list-title title="通知资讯" tip="后勤服务动态一目了然" more="查看更多>" class="mt_12" @click="onMoreList"></list-title>
		<!-- 新闻 -->
		<div class="news_list" v-for="item in list" :key="item.value" @click="onDetails(item)">
			<div class="news_list_text_wrap">
				<div class="news_list_title">{{ item.title }}</div>
				<div class="news_list_tip">
					<!--					<div class="news_tip">新闻标签</div>-->
					<div class="news_time">{{ item.createTime }}</div>
				</div>
			</div>
			<van-image width="100" height="75" fit="cover" :src="item.image" />
		</div>
		<div style="background-color:#ffffff"><van-divider class=" divider">已经到底了</van-divider></div>
	</div>
</template>
<script>
import listTitle from '@/components/list-title.vue';
import { zmNoticeList } from '@/api/repairs.js';
export default {
	props: {},
	components: { listTitle },
	data() {
		return {
			msg: '首页',
			userInfo: {
				nick: '',
				userName: '',
				profile: '',
				phone: ''
			},
			images: ['../../assets/image/banner1.png', '../../assets/image/banner1.png'],
			list: [],
			finished: false,
			loading: false,
			refreshing: false,
			queryParams: {
				order: 'desc',
				pageNo: 1,
				pageSize: 10,
				queryField: [
					{
						field: '',
						queryType: '',
						type: '',
						value: ''
					}
				],
				sort: 'createTime'
			}
		};
	},
	created() {
		var user = localStorage.getItem('userInfo');
		if (user) {
			user = JSON.parse(user);
			this.userInfo.nick = user.nick;
			this.userInfo.userName = user.userName;
			this.userInfo.phone = user.phone;
			this.userInfo.profile = user.profile;
		}
		this.getZmNoticeList();
	},
	mounted() {},
	methods: {
		//
		onDetails(item) {
			this.$router.push({
				name: 'noticeDetails',
				query: {
					uuid: item.uuid
				}
			});
		},
		// 维修
		onPersonal() {
			this.$router.push({
				name: 'personalIndex'
			});
		},
		// 维修
		onRepairs() {
			this.$router.push({
				name: 'repairs'
			});
		},
		// 投诉建议
		onComplaint() {
			this.$router.push({
				name: 'complaint'
			});
		},
		// 新闻资讯查看更多
		onMoreList() {
			console.log('查看更多咨询');
			this.$router.push({
				name: 'noticeList'
			});
		},
		// 通知资讯
		onInform() {
			this.$router.push({
				name: 'noticeList'
			});
		},
		// 我的服务
		onMyServer() {
			console.log('我的服务');
			this.$router.push({
				name: 'serve'
			});
		},
		//获取新闻
		getZmNoticeList() {
			zmNoticeList(this.queryParams)
				.then(res => {
					if (res.code == 200) {
						this.list = res.rows;
						if (this.queryParams.pageNo >= res.totalPage) {
							this.finished = true;
						}
					} else {
						this.$toast.fail(res.message);
					}
				})
				.finally(() => {
					this.loading = false;
					this.refreshing = false;
				});
		}
	}
};
</script>
<style lang="scss" scoped>
.page_area {
	background-color: #eeeeee;
}
// 顶部的显示区域
.top_area {
	height: 178px;
	padding: 15px;
	color: #ffffff;
	background-color: #1eaa72;
	// background-image: url(img/top_area_bg.41a85a6f.png);
	background-position: left center;
	background-size: 100% auto;
	background-repeat: no-repeat;
	.top_center {
		margin-top: 52px;
		width: 100%;
		height: 72px;
		display: flex;
		position: relative;
		flex: 1;
		.top_center_text {
			margin: 8px 0;
			margin-left: 16px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			flex: 1;
		}
		.tip {
			position: absolute;
			right: 0;
			top: -10px;
			width: 50px;
			height: 30px;
			font-size: 10px;
		}
	}
}
.grid_area {
	margin: -38px 14px 12px 14px;
	border-radius: 10px;
	/deep/ .van-grid-item__text {
		font-size: 14px;
	}
}
// 轮播图
.swipe_wrap {
	height: 147px;
	margin: 0 14px;
	// border-radius: 8px;
	.swipe_item {
		width: 100%;
		height: 100%;
	}
}
// 垂直广告
.notice-swipe {
	height: 40px;
	line-height: 40px;
}
.divider {
	width: 50%;
	margin: 0 auto;
}
.news_list {
	background-color: #ffffff;
	display: flex;
	padding: 15px 15px 20px 15px;
	.news_list_text_wrap {
		width: 100%;
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin: 0 4px;
		border-radius: 4px;
		.news_list_title {
			color: #051b28;
			overflow: hidden;
			display: -webkit-box;
			text-overflow: ellipsis;
			-webkit-line-clamp: 2; /*要显示的行数*/
			-webkit-box-orient: vertical;
			font-size: 18px;
		}
		.news_list_tip {
			display: flex;
			align-items: center;
			width: 100%;
			justify-content: space-between;
			.news_tip {
				background-color: #aee7d0;
				color: #ffffff;
				font-size: 10px;
				padding: 3px 5px;
			}
			.news_time {
				color: #b2b2b2;
				font-size: 14px;
			}
		}
	}
}
.news_list + .news_list {
	border-top: 1px solid #e5e5e5;
}
</style>
