<template>
  <div class="list_title_warp">
    <div class="split"></div>
    <div class="text">{{title}}</div>
    <div class="more" @click="click">{{more}}</div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default () {
        return ''
      }
    },
    more: {
      type: String,
      default () {
        return ''
      }
    },
    // click: {
    //   type: Object,
    //   default () {
    //     return function (){
    //
    //     }
    //   }
    // }
  },
  components: {},
  data () {
    return {
      msg: 'Hello World!'
    }
  },
  created () { },
  mounted () { },
  methods: {
    click(){
      this.$emit('click', {})
    }
  }
}
</script>
<style lang="scss" scoped>
.list_title_warp {
  height: 24px;
  padding: 14px;
  display: flex;
  align-items: center;
  background-color: #fff;
  padding-top: 18px;
  .split {
    width: 6px;
    height: 100%;
    border-radius: 4px;
    background-color: #1eaa72;
  }
  .text {
    color: #333333;
    font-size: 18px;
    flex: 1;
    margin-left: 8px;
    font-weight: bold;
  }
  .more {
    color: #999999;
    font-size: 14px;
  }
}
</style>